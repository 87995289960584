<template>
  <template v-if="availableOfferImage && availableOfferVideo">
    <template v-if="displayVideo">
      <video
        :src="availableOfferVideo.url"
        :height="height"
        :width="width"
        autoplay
        loop
        muted
        playsinline
        @click="handleClick"
      />
    </template>
    <template v-else>
      <img
        :src="availableOfferImage.url"
        :width="width"
        :height="height"
        :alt="availableOfferImage.filename"
        @click="handleClick"
      />
    </template>
  </template>
  <template v-else-if="availableOfferImage">
    <img
      :src="availableOfferImage.url"
      :width="width"
      :height="height"
      :alt="availableOfferImage.filename"
      @click="handleClick"
    />
  </template>
  <template v-else-if="availableOfferVideo">
    <video
      :src="availableOfferVideo.url"
      :height="height"
      :width="width"
      autoplay
      loop
      muted
      playsinline
      @click="handleClick"
    />
  </template>
</template>

<script setup>
  const props = defineProps({
    offer: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 250,
    },
  })

  const videoDisplayRate = 0.85
  const dimensions = `${props.width}x${props.height}`

  const displayVideo = Math.random() < videoDisplayRate

  const availableOfferImage = computed(() => {
    const allImages = props.offer.images
    const availableImages = allImages.filter(
      (image) => image.dimensions === dimensions,
    )

    if (availableImages.length > 0) {
      return availableImages[Math.floor(Math.random() * availableImages.length)]
    } else {
      return null
    }
  })

  const availableOfferVideo = computed(() => {
    const allVideos = props.offer.videos
    const availableVideos = allVideos.filter(
      (video) => video.dimensions === dimensions,
    )

    if (availableVideos.length > 0) {
      return availableVideos[Math.floor(Math.random() * availableVideos.length)]
    } else {
      return null
    }
  })

  const toast = useToast()
  const handleClick = async () => {
    const { data } = await useApi("/api/upgrade/offer", {
      method: "POST",
      body: JSON.stringify({
        option: props.offer.id,
      }),
    })

    if (data.value.error) {
      toast.add({
        title: "Error",
        description: data.value.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } else {
      toast.add({
        title: "Success",
        description: "Your purchase was successful",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    }
  }
</script>
