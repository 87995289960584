<template>
  <slot>
    <iframe
      :src="src"
      allowtransparency="true"
      :width="width"
      :height="height"
      hspace="0"
      vspace="0"
      scrolling="no"
      frameborder="0"
      marginwidth="0"
      marginheight="0"
    />
  </slot>
</template>

<script lang="ts" setup>
  const props = defineProps({
    auto: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 250,
    },
    keywords: {
      type: String,
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      default: "!autodetect",
    },
    spaceId: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
  })

  const src = `//go.go-srv.com/banner.go?spaceid=${props.spaceId}&keywords=${props.keywords}&auto=${props.auto}&size=${props.size}`
</script>
